window.addEventListener("DOMContentLoaded", () => {
    console.log(':)');
    const globalBody = document.querySelector("body");

    try {
        let burger = document.querySelector("#burger");
        let burgerText = document.querySelector("#burger .text");
        let headerNavs = document.querySelector(".header__navs");
        burger.addEventListener("click", (e) => {
            e.preventDefault();
            burger.classList.toggle("show");
            headerNavs.classList.toggle("show");

            if (burger.classList.contains("show")){
                burgerText.innerHTML = 'Закрыть';
            } else {
                burgerText.innerHTML = 'Меню';
            }
        })
    } catch (e) {
        console.log(e + "header");
    }

    try{
        let modal = document.querySelector("#modal"),
            modalClose = document.querySelector("#modal-close"),
            showModal = document.querySelectorAll(".show-modal");

        showModal.forEach((item) => {
            item.addEventListener("click", (e) => {
                e.preventDefault();
                document.querySelector("body").classList.add("body-fixed");
                modal.style.display = "flex";
            })
        });
        modalClose.addEventListener("click", (e) => {
            e.preventDefault();
            document.querySelector("body").classList.remove("body-fixed");
            modal.style.display = "";
        });


    } catch (e){

    }
    try {
        const anchors = document.querySelectorAll(".anchors");
        anchors.forEach((item) => {
            item.addEventListener('click', function (e) {
                e.preventDefault();
                const blockID = item.getAttribute('href').substr(1);
                document.getElementById(blockID).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
                anchors.forEach((i) => {
                    i.classList.remove("active");
                });
                item.classList.add("active");
            })
        });
    }catch(e){
        console.log(e + "якоря");
    }

});

try {
    $(document).ready(function () {
        /* отзывы */
        $('.factory__slider').slick({
            arrows: true,
            dots: false,
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            easing: 'ease',
            infinite: true,
            initialSlide: 0,
            autoplay: false,
            autoplaySpeed: 9000,
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            swipe: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        $('.manufacturing-slider-1').slick({
            arrows: true,
            dots: false,
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            easing: 'ease',
            infinite: true,
            initialSlide: 0,
            autoplay: false,
            autoplaySpeed: 9000,
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            swipe: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
        $('.manufacturing-slider-2').slick({
            arrows: true,
            dots: false,
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            easing: 'ease',
            infinite: true,
            initialSlide: 0,
            autoplay: false,
            autoplaySpeed: 9000,
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            swipe: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
        $('.manufacturing-slider-3').slick({
            arrows: true,
            dots: false,
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            easing: 'ease',
            infinite: true,
            initialSlide: 0,
            autoplay: false,
            autoplaySpeed: 9000,
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            swipe: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
        $('.manufacturing-slider-4').slick({
            arrows: true,
            dots: false,
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            easing: 'ease',
            infinite: true,
            initialSlide: 0,
            autoplay: false,
            autoplaySpeed: 9000,
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            swipe: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
        $('.manufacturing-slider-5').slick({
            arrows: true,
            dots: false,
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            easing: 'ease',
            infinite: true,
            initialSlide: 0,
            autoplay: false,
            autoplaySpeed: 9000,
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            swipe: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    });
} catch (e) {
    console.log("slider" + e);
}